<template>
    <div class="main-container">
        <div class="course-container">
            <div class="label">上传视频</div>
            <wj-upload
                action=""
                drag
                class="activity-uploader"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :http-request="handleFileUpload"
                ref="uploadFile"
            >
                <div class="uploader-container" v-if="fileList.length == 0">
                    <i class="iconfont icon-shangchuan1"></i>
                    <wj-button type="primary">上传文件</wj-button>
                    <div class="sign">支持拖拽文件上传，MP4等格式</div>
                </div>
                <div class="uploader-filelist" v-else>
                    <div class="file-item" v-for="(item, index) in fileList" :key="index">
                        <i class="wj-icon-close" @click.stop="delFile"></i>
                        <div class="file-name">{{ item.fileName }}</div>
                        <div class="upload-status" v-if="item.status == '1'">等待中...</div>
                        <div class="upload-status" v-else-if="item.status == '2'">
                            {{ item.progress }} 上传中...
                        </div>
                        <div class="upload-status" v-else-if="item.status == '3'">
                            <i class="wj-icon-success" style="color: #1a78ff"></i>上传完成
                        </div>
                    </div>
                </div>
            </wj-upload>
        </div>
        <div class="course-container">
            <div class="label">基本设置</div>
            <wj-form ref="addFormRef" :model="addFormRef" label-width="80px">
                <wj-form-item label="标题：">
                    <wj-input v-model="addFormRef.title" placeholder="请输入课程标题"></wj-input>
                </wj-form-item>
                <wj-form-item label="简介：">
                    <wj-input
                        type="textarea"
                        v-model="addFormRef.introduction"
                        placeholder="请输入简介"
                    ></wj-input>
                </wj-form-item>
            </wj-form>
            <div class="btn-options">
                <wj-button
                    class="btn-upload"
                    type="primary"
                    @click="addCourse"
                    :disabled="fileList[0] && fileList[0].status == 2"
                    >立即上传</wj-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import { Upload, Button, Form, FormItem, Input } from '@wenjuan/ui';
import { httpPostAdminCourseEpisode, httpUpdateVideoStatus } from '@/http/admin/index.js';
export default {
    data() {
        return {
            addFormRef: {
                title: '', //标题
                introduction: '', //简介
                fileName: '',
                courseId: this.$route.query.cid,
            },
            uploader: null,
            uploaderInfo: {},
            fileList: [],
        };
    },
    created() {},
    components: {
        'wj-upload': Upload,
        'wj-button': Button,
        'wj-form': Form,
        'wj-form-item': FormItem,
        'wj-input': Input,
    },
    methods: {
        createUploader() {
            let _this = this;
            let uploader = new AliyunUpload.Vod({
                region: 'cn-beijing',
                userId: '1353771044907490',
                onUploadstarted: function (uploadInfo) {
                    let { uploadAddress, uploadAuth, videoId } = _this.uploaderInfo;
                    uploader.setUploadAuthAndAddress(
                        uploadInfo,
                        uploadAuth,
                        uploadAddress,
                        videoId
                    );
                },
                // 文件上传成功
                onUploadSucceed: function () {
                    httpUpdateVideoStatus(_this.uploaderInfo.episodeId).then(() => {
                        _this.$message({
                            type: 'success',
                            message: '上传成功',
                            duration: 2000,
                        });
                        _this.$router.go(-1);
                    });
                    _this.fileList[0].status = 3;
                },
                onUploadProgress(uploadInfo, totalSize, progress) {
                    _this.fileList[0].progress = Math.ceil(progress * 100) + '%';
                },
            });
            return uploader;
        },
        beforeUpload(file) {
            const isLtType = [
                'video/mp4',
                'video/ogg',
                'video/flv',
                'video/avi',
                'video/wmv',
                'video/rmvb',
                'video/mov',
            ];
            if (isLtType.indexOf(file.type) == -1) {
                this.$message({
                    message: '视频格式错误，上传失败',
                    type: 'warning',
                });
                return false;
            }
        },
        //自定义上传
        handleFileUpload(file) {
            this.addFormRef.fileName = file.file.name;
            this.uploader = this.createUploader();
            // var userData = `{"Vod":"11","Title":${file.file.name}}`;
            var userData = '{}';

            this.uploader.addFile(file.file, null, null, null, userData);
            this.fileList = [
                {
                    fileName: file.file.name,
                    status: '1', //1:等待中 2：上传中 3：上传完成
                    progress: '0%',
                },
            ];
            // this.uploader.startUpload();
        },
        addCourse() {
            if (this.fileList.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请上传课程视频',
                    duration: 2000,
                });
                return;
            }
            if (this.addFormRef.title == '') {
                this.$message({
                    type: 'warning',
                    message: '请输入课程标题',
                    duration: 2000,
                });
                return;
            }
            // this.showLoading();
            httpPostAdminCourseEpisode(this.addFormRef)
                .then((res) => {
                    let data = res.data.data;
                    this.uploaderInfo = data;
                    this.fileList[0].status = 2;
                    this.fileList[0].progress = '0%';
                    this.uploader.startUpload();
                })
                .catch(() => {
                    this.hideLoading();
                });
        },
        delFile() {
            this.$refs.uploadFile.clearFiles();
            this.fileList = [];
        },
    },
};
</script>
<style scoped lang="scss">
.main-container {
    width: 1330px;
    background: #ffffff;
    margin: 40px auto;
    .course-container {
        padding: 0 40px;
        box-sizing: border-box;
        .label {
            font-size: 18px;
            padding: 40px 0 30px 0;
        }
        .activity-uploader {
            width: 100%;
            height: 210px;

            ::v-deep .wj-upload {
                width: 100%;
                height: 100%;
                .wj-upload-dragger {
                    height: 100%;
                    width: 100%;
                }
                .uploader-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #aaaaaa;
                    background: #f5f7fa;
                    .wj-button {
                        width: 136px;
                        height: 40px;
                        margin-top: 20px;
                        border-radius: 4px;
                    }
                    .iconfont {
                        font-size: 40px;
                        color: #979797;
                    }
                    .sign {
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }
                .uploader-filelist {
                    width: 100%;
                    height: 100%;
                    background: #f5f7fa;
                    overflow: hidden;
                    .file-item {
                        width: 236px;
                        height: 70px;
                        background-color: #fff;
                        border-radius: 4px;
                        text-align: left;
                        padding: 10px 16px;
                        margin: 10px;
                        position: relative;
                        color: #3c3c3c;
                        .file-name {
                            width: 165px;
                            word-wrap: break-word;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .upload-status {
                            margin-top: 10px;
                        }
                        .wj-icon-close {
                            position: absolute;
                            right: 15px;
                            top: 15px;
                            font-size: 16px;
                            color: #aaadb1;
                            cursor: pointer;
                            &:hover {
                                color: #1a78ff;
                            }
                        }
                    }
                }
            }
        }
        .wj-input {
            width: 780px;
        }
        .wj-textarea {
            width: 780px;
            height: 92px;
            ::v-deep .wj-textarea__inner {
                height: 92px;
            }
        }
        .btn-options {
            width: 100%;
            text-align: center;
            padding: 20px 0 40px;
            .btn-upload {
                width: 136px;
                height: 40px;
                border-radius: 4px;
            }
        }
        ::v-deep .wj-form-item__label {
            text-align: left;
        }
    }
}
</style>
